<template>
	<div>
		<div :class="['my-flex-spa-ar home-nav-wrap', {'mobile-nav-height':mobileNavHeight}]">
			<div @click="goTop" class="logo-wrap">
				<img class="logo-img" :src="logo" alt="">
			</div>
			<!--产品特点介绍  产品展示 定制流程 未来火星计划 登记册-->
			<div class="my-flex menus-wrap-map">
				<div v-if="menusVisible">
					<Input v-model="keyword"
					       @on-search="searchNow"
					       search enter-button
					       :style=" widthMobile "
					       placeholder="输入证书编号" />
				</div>
				<Menu @on-select="onRouterChange" v-if=" menusVisible " mode="horizontal" :theme="theme1"
				      active-name="2">
					<MenuItem name="1">
						<Icon type="ios-paper"/>
						首页
					</MenuItem>
					<MenuItem name="2">
						<Icon type="ios-people"/>
						未来火星计划
					</MenuItem>
					<MenuItem name="3">
						<Icon type="ios-construct"/>
						F&Q
					</MenuItem>
				</Menu>
				<div v-else class="my-flex" style="justify-content: flex-end">
					<Dropdown @on-click="onSelectMobileMenus">
						<Icon size="40" color="#fff" type="md-menu"></Icon>
						<DropdownMenu slot="list">
							<DropdownItem name="1">
								<Icon type="md-filing" />
								首页</DropdownItem>
							<DropdownItem name="2">
								<Icon custom="iconfont icon-mars_"/>未来火星计划</DropdownItem>
							<DropdownItem name="3">
								<Icon custom="iconfont icon-question"/>F&Q</DropdownItem>
						</DropdownMenu>
					</Dropdown>
				</div>
			</div>
		</div>
		<div style="height: 54px;width: 100%"></div>
<!--		展示搜索框-->
		<div style="text-align: center;padding: 13px 0;display: flex;justify-content: center" v-if="!menusVisible">
			<Input v-model="keyword"
			       @on-search="searchNow"
			       search enter-button
			       :style=" widthMobile2 "
			       placeholder="输入证书编号" />
		</div>
		
		<Map :position=" position " ref="myMap"/>
<!--		电脑端显示内容-->
		<div v-if="menusVisible && content!==null" ref="contentRef" class="info-wrap">
			<p v-html="content "></p>
		</div>
<!--		手机端显示-->
		<div v-if="!menusVisible && content!==null" ref="contentRef" class="mobile-info-wrap">
			<p v-html="content "></p>
		</div>
		<!--		底部大图片-->
<!--		<div v-if="content!==null && !menusVisible" style="height: 370px"></div>-->
		<div style="max-width: 100%;height: auto">
			<img style="width: 100%;height: 100%" :src="thumb" alt="">
<!--			<img style="width: 100%;height: 100%" src="https://z3.ax1x.com/2021/03/31/cFHDfO.jpg" alt="">-->
		</div>
	</div>
</template>

<script>
import Map from '@/components/Map.vue'

export default {
	name: "About",
	data(){
		return {
			id: '',
			noticeListMargin: '80px',
			_info: {},
			menusVisible: !0,
			theme1: 'dark',
			content: null,
			rowClassName: 'row-style',
			keyword: '',
			position: {},
			widthMobile: {
				width: '300px'
			},
			widthMobile2: {
				width: '80%'
			},
			infoClass: 'info-wrap',
			
			columns1: [
				{
					title: '标记点',
					key: 'title'
				},
				{
					title: '姓名',
					key: 'name'
				},
				{
					title: '经纬度',
					key: 'location'
				},{
					title: '登记时间',
					key: 'created_at'
				}
				
			],
			data1: [],
			loading: !0,
			logo: localStorage.getItem('logo'),
			proportion: 7,
			mobileNavHeight: !1,
			thumb:'',
			
			contentRefHeight: '',
			noticeListClass: 'notice-list-wrap'
		}
	},
	components: {
		Map
	},
	inject: ['reload'],
	mounted() {
		if (localStorage.getItem('_data')){
			let obj = JSON.parse(localStorage.getItem('_data'))
			// let data = decodeURIComponent(this.$route.params.obj)
			// let obj = JSON.parse(data)
			let position = {
				lng:Number(obj.longitude),
				lat:Number(obj.latitude)
			}
			this.position = position
			this._info = obj
			this.content = obj.content
		}
		
		this.getScreen()
		this.loadList()
		this._loadCopyRight()
		// this.$refs.myMap.pointInit()
	},
	methods: {
		onSelectMobileMenus(e){
			if (2==e){
				this.$router.push({
					path: '/about'
				})
			}else if (3==e){
				this.$router.push({
					path: '/question'
				})
			}else if (1==e){
				// location.href = '/'
				this.$router.push({
					path: '/'
				})
			}
		},
		/**
		 * 路由切换
		 */
		onRouterChange(e){
			console.log(e);
			if (1==e){
				this.$router.push({
					path: '/'
				})
			}else if (3==e){
				this.$router.push({
					path: '/question'
				})
			}
		},
		/**
		 * 加载花名册
		 */
		loadList(){
			this.request({
				url: '/api/getCoordinate_hmc'
			}).then(res=>{
				this.loading = !1
				if (res.data.length>0){
					let tempList = res.data.map(item=>{
						return {
							title: item.title,
							name: item.name,
							created_at: item.created_at,
							location: `${item.latitude},${item.longitude}`
						}
					})
					this.data1 = tempList
				}
				console.log(res,'======花名册数据=====');
			})
		},
		getScreen(){
			let w = document.documentElement.offsetWidth || document.body.offsetWidth;
			if (w < 1000){ // 手机
				console.log('我是手机出来的');
				this.widthMobile.width = '100px'
				this.infoClass = 'mobile-info-wrap'
				this.mobileNavHeight = !1
				this.menusVisible = false
				this.noticeListClass = 'mobile-notice-list-wrap'
				// this.proportion = 12
				// this.$refs.myMap.init()
				console.log(this.proportion,'=====proportion====');
			}
		},
		goTop(){
			console.log('okk');
			window.scrollTo( 0, 0 )
		},
		searchNow(){
			if (''==this.keyword){
				this.$Message.warning('请输入编号');
				return
			}
			this.loading = !0
			this.request({
				url: `/api/getCoordinateDetail/${this.keyword}`
			}).then(res=>{
				this.loading = !1
				console.log(res,'=====getCoordinateDetail====');
				let data = JSON.stringify(res.data)
				if (0==res.code){
					if (Array.isArray(res.data) && 0==res.data.length){
						this.$Message.warning('未查询到数据');
					}else{
						// localStorage.removeItem('_data')
						localStorage.setItem('_data', data)
						// window.location.reload()
						
						this.reload()
						
						//
						// let position = {
						// 	lng:Number(data.longitude),
						// 	lat:Number(data.latitude)
						// }
						// this.position = position
						// this._info = data
						// this.content = data.content
						// this.$router.go(0)
					}
				}
			})
		},
		_loadCopyRight(){
			this.request({
				url: '/api/getSetting'
			}).then(res=>{
				console.log(res,'=====getSetting====');
				if (0==res.code){
					this.logo = res.data.logo
					this.thumb = res.data.thumb
				}
			})
		},
	},
	beforeCreate () {
		// document.querySelector('body').setAttribute('style', 'background-color:#fff!important;');
		window.scrollTo( 0, 0 )
		// Firefox
		// document.documentElement.scrollTop = 0
		// Safari
		// window.pageYOffset = 0
	},
	beforeDestroy() {
		// localStorage.removeItem('_data')
		window.scrollTo( 0, 0 )
		document.querySelector('body').setAttribute('style', 'background-color:#000000!important;');
	},
}
</script>

<style>
	/*.notice-list-wrap .el-table{*/
	/*	background-color: #000000!important;*/
	/*	color: #ffffff!important;*/
	/*}*/
	.menus-wrap-map .ivu-menu-dark{
		background: #000000!important;
	}
	
	.el-table,
	.el-table__expanded-cell {
		background-color: transparent !important;
		color: #ffffff;
	}
	.el-table th,
	.el-table tr,
	.el-table td {
		background-color: transparent !important;
		color: #ffffff;
	}
</style>

<style scoped lang="scss">
	@import "../style/about.scss";
	//body{
	//	background-color: #fff;
	//}
	.info-wrap{
		width: 300px;
		//height: 500px;
		background-color: rgba(0,0,0, 0.2);
		background-color: #707173;
		color: #ffffff;
		position: absolute;
		right: 100px;
		top: 80px;
		z-index: 100;
		overflow: hidden;
		padding: 13px;
	}
	.mobile-info-wrap{
		width: 100%;
		background-color: rgba(0,0,0, 0.2);
		color: #ffffff;
		//position: absolute;
		//top: 711px;
		//z-index: 100;
		overflow: hidden;
		padding: 13px;
	}
	.notice-list-wrap{
		width: 85%;
		margin-left: auto;
		margin-right: auto;
		margin-top: 80px;
	}
	.mobile-notice-list-wrap{
		width: 85%;
		margin-left: auto;
		margin-right: auto;
		margin-top: 350px;
	}
</style>
